import React from 'react';
import { IMaskMixin } from 'react-imask';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';

import './InputPhone.scss';

const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => {
    const prop = { ...props };
    const errorMessage = typeof prop['error'] === 'string' ? prop['error'] : '';
    const successMessage = typeof prop['success'] === 'string' ? prop['success'] : '';
    return (
        <>
            <input {...props} ref={inputRef} />
            {(prop['error'] || prop['success']) && (
                <Form.Text
                    className={clsx(
                        ['text-muted'],
                        prop['error'] && 'invalid-feedback',
                        prop['success'] && 'valid-feedback'
                    )}
                >
                    {prop['error']
                        ? errorMessage
                        : prop['success']
                        ? successMessage
                        : prop['message']}
                </Form.Text>
            )}
        </>
    );
});

const InputPhone = ({ label, ...other }) => {
    return (
        <div className='form__input'>
            <div className='form-floating'>
                <Form.Group className='mb-2'>
                    <Form.Label className={clsx(['form-label'])}>{label}</Form.Label>
                    <MaskedStyledInput {...other} />
                </Form.Group>
            </div>
        </div>
    );
};

export default InputPhone;
