import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import Hamburger from '@kit/Hamburger';
import Menus from '@api/Menus';

import { clearLink } from '@helpers/clearLink';

import './Navigation.module.scss';

type NavigationProp = {
    id?: number;
    switcher?: boolean;
    show?: boolean;
    setShow?: React.Dispatch<React.SetStateAction<boolean>>;
    setMobilePlumbMenu?: React.Dispatch<React.SetStateAction<boolean>>;
};

type MenuProp = {
    items?: any;
};

const Navigation = ({
    id = 16,
    switcher = true,
    show = false,
    setShow,
    setMobilePlumbMenu
}: NavigationProp) => {
    const [menus, setMenus] = useState<MenuProp>({
        // items: {}
        items: {}
    });
    const btnHandler = () => {
        document.documentElement.classList.toggle('menu--show');
        show
            ? (setShow(false), setMobilePlumbMenu(false))
            : (setShow(true), setMobilePlumbMenu(true));
    };

    useEffect(() => {
        Menus.get({ id })
            .then(({ data }) => {
                if (Object.keys(data).length) {
                    setMenus((prevState) => ({
                        ...prevState,
                        loading: false,
                        data,
                        items: data.items
                    }));
                } else {
                    setMenus((prevState) => ({
                        ...prevState,
                        loading: false
                    }));
                }
            })
            .catch((err) => {
                setMenus((prevState) => ({
                    ...prevState,
                    loading: false
                }));
            });
    }, [id]);

    return (
        <nav className={clsx(['nav', show === true && 'nav--show'])}>
            {switcher && <Hamburger className='nav__switcher' handler={btnHandler} />}
            <ul className='nav__list'>
                {menus?.items?.length
                    ? menus.items?.map((item, index) => (
                          <li key={item.ID} className='nav__item'>
                              <NavLink
                                  activeClassName='nav__link--current'
                                  className='nav__link'
                                  to={clearLink(item.url)}
                                  exact
                                  onClick={(e) => {
                                      if (switcher) {
                                          document.documentElement.classList.remove('menu--show');
                                          setShow(false);
                                          setMobilePlumbMenu(false);
                                      }
                                  }}
                              >
                                  {item.title}
                              </NavLink>
                              {item?.child_items && item.child_items?.length && (
                                  <ul className='nav__submenu'>
                                      {item.child_items.map((child) => (
                                          <li key={child.ID} className='nav__submenu-item'>
                                              <NavLink
                                                  activeClassName='nav__submenu-link--current'
                                                  className='nav__submenu-link'
                                                  to={clearLink(child.url)}
                                                  exact
                                                  onClick={(e) => {
                                                      if (switcher) {
                                                          document.documentElement.classList.remove(
                                                              'menu--show'
                                                          );
                                                          setShow(false);
                                                          setMobilePlumbMenu(false);
                                                      }
                                                  }}
                                              >
                                                  {child.title}
                                              </NavLink>
                                          </li>
                                      ))}
                                  </ul>
                              )}
                          </li>
                      ))
                    : ''}
            </ul>
        </nav>
    );
};

export default Navigation;
