import React from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';

import './Page.module.scss';

const Page = ({ header = false, className = '', children }) => {
    return (
        <Container fluid className={clsx(['page', 'g-0', className && className])}>
            {children}
        </Container>
    );
};

export default Page;
