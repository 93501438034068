import { ClientApi } from './Request';
import { BreadcrumbsProp } from '@store/types';
import { baseConfig } from '@store/baseConfig';

const { SITE_URL } = baseConfig();

const Breadcrumbs = {
    get: ({ id, type }: BreadcrumbsProp) =>
        ClientApi.get(`${SITE_URL}/wp-json/bcn/v1/${type}/${id}`)
};

export default Breadcrumbs;
