import Home from '@containers/Home';
import About from '@containers/About';
import Contacts from '@containers/Contacts';
import Menu from '@containers/Menu';
import Services from '@containers/Services';
import Articles from '@containers/Articles';
import Product from '@containers/Products';
import ProductPage from '@containers/ProductPage';
import StaticPost from '@containers/StaticPost';
import StaticPage from '@containers/StaticPage';
import NotFound from '@containers/NotFound';

const Routes = [
    {
        path: '/',
        exact: true,
        component: Home
    },
    {
        path: '/about',
        exact: true,
        component: About
    },
    {
        path: '/about/:slug',
        exact: true,
        component: StaticPage
    },
    {
        path: '/o-nas',
        exact: true,
        component: StaticPage
    },
    {
        path: '/contacts',
        component: Contacts
    },
    {
        path: '/menu',
        exact: true,
        component: Menu
    },
    {
        path: '/menu/:slug',
        exact: true,
        component: StaticPost
    },
    {
        path: '/services',
        exact: true,
        component: Services
    },
    {
        path: '/services/:slug',
        exact: true,
        component: StaticPost
    },
    {
        path: '/articles',
        exact: true,
        component: Articles
    },
    {
        path: '/articles/:slug',
        exact: true,
        component: StaticPost
    },
    {
        path: '/places',
        exact: true,
        component: Product
    },
    {
        path: '/places/:slug',
        exact: true,
        component: ProductPage
    },
    // {
    //   path: '/posts',
    //   component: Posts,
    //   loadData: () => loadData('posts')
    // },
    // {
    //   path: '/todos',
    //   component: Todos,
    //   loadData: () => loadData('todos')
    // },
    {
        component: NotFound
    }
];

export default Routes;
