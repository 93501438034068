import React, { useState, useEffect, useRef } from 'react';
import Page from '@containers/Page';

import Questions from './components/Questions';

import ArticlesList from '@components/ArticlesList';
import MenuList from '@components/MenuList';
import PrimarySlider from '@components/PrimarySlider';
import SearchList from '@components/SearchList';

import { baseConfig } from '@store/baseConfig';

import Categories from '@api/Categories';
import Forms from '@api/Forms';

import './Home.module.scss';
//import json from './json.json';

const Home = () => {
    const [cats, setCats] = useState([]);
    const ref = useRef(null);
    const { SITE_QUESTIONS, SITE_PRIMARY_SLIDER } = baseConfig();

    const onScrollEffect = () => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const { SITE_INDEX_PAGE } = baseConfig();

        Categories.get({ id: SITE_INDEX_PAGE })
            .then(({ data }) => {
                setCats(data);
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <Page className='page--home'>
            <PrimarySlider
                cats={Object.values(cats).filter((cat) => cat.id === SITE_PRIMARY_SLIDER)}
                onScrollEffect={onScrollEffect}
            />
            <SearchList onScrollEffect={onScrollEffect} PromiseApi={Forms.send} ref={ref} />
            <MenuList slider={true} />
            <Questions content={Object.values(cats).filter((cat) => cat.id === SITE_QUESTIONS)} />
            <ArticlesList slider={true} />
        </Page>
    );
};

export default Home;
