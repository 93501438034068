import React from 'react';
import clsx from 'clsx';
import './ContentSection.module.scss';

type ContentSectionLinkProp = {
    name: string;
    href: string;
};

export type ContentSectionProp = {
    subtitle?: string;
    title?: string;
    text?: string;
    link?: ContentSectionLinkProp;
    className?: string;
};

const ContentSection = ({ subtitle, title, text, link, className }: ContentSectionProp) => {
    return (
        <div className={clsx(['content-section', className && className])}>
            {subtitle && <div className='content-section__subtitle'>{subtitle}</div>}
            {title && (
                <div className='content-section__title'>
                    {title}
                    {link && (
                        <a className='content-section__link' href={link.href}>
                            {link.name}
                        </a>
                    )}
                </div>
            )}
            {text && (
                <div className='content-section__text' dangerouslySetInnerHTML={{ __html: text }} />
            )}
        </div>
    );
};

export default ContentSection;
