import React, { useEffect, useState } from 'react';

import Page from '@containers/Page';

import ProductContent from '@components/ProductContent';

import Product from '@api/Product';

import { BreadcrumbsProp } from '@store/types';

import Layout from '@kit/Layout';
import Preloader from '@kit/Preloader';

import './ProductPage.module.scss';
//import json from './json.json';

type PostProp = {
    loading: boolean;
    data?: any;
    error?: string;
};

const ProductPage = (props) => {
    const { slug = '' } = { ...props.match.params };

    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsProp>({
        type: 'product',
        id: null
    });
    const [post, setPost] = useState<PostProp>({
        loading: true,
        data: {},
        error: null
    });

    const { loading, data, error } = { ...post };

    useEffect(() => {
        Product.get({ slug })
            .then(({ data }) => {
                if (Object.keys(data).length) {
                    setPost((prevState) => ({
                        ...prevState,
                        loading: false,
                        data
                    }));
                    setBreadcrumbs((prevState) => ({
                        ...prevState,
                        type: 'post',
                        id: data[0].id
                    }));
                } else {
                    setPost((prevState) => ({
                        ...prevState,
                        loading: false,
                        error: 'Такая запись не найдена'
                    }));
                }
            })
            .catch((err) => {
                setPost((prevState) => ({
                    ...prevState,
                    loading: false,
                    error: `Ошибка: ${err.message}`
                }));
            });
    }, [slug]);

    return (
        <Page>
            {loading && <Preloader />}
            <Layout
                title={data[0]?.title ?? null}
                breadcrumbs={breadcrumbs}
                error={error}
                image={data[0]?.image ?? null}
                types='product'
            />
            {data.length ? <ProductContent datas={data[0]} /> : ''}
        </Page>
    );
};

export default ProductPage;
