import { ModalAsyncContentProp } from '@store/types';
import MicroModal, { ModalCreate } from '@kit/Modal';
import LoaderReactAsync from '@helpers/LoaderReactAsync';
import './ModalAsyncContent.scss';

const modals = {};

const ModalAsyncContent = ({ module, moduleDataset }: ModalAsyncContentProp) => {
    const moduleProps = moduleDataset ? moduleDataset : null;
    const moduleName = moduleDataset?.module;
    const idModal = `modal-ajax-${moduleName}${
        moduleProps && moduleProps?.id ? `-${moduleProps?.id}` : ''
    }`;

    const modalConfig = {
        disableFocus: true,
        awaitCloseAnimation: true
    };

    if (modals[idModal]) return MicroModal.show(idModal, modalConfig);

    const modal: HTMLElement = ModalCreate({
        idModal,
        content: `<div class="modal-content-root"></div>`
    });

    const root: HTMLElement = modal.querySelector('.modal-content-root');

    modals[idModal] = {
        modal,
        root,
        pending: true
    };

    LoaderReactAsync(module, root, moduleProps)
        .then(() => {
            MicroModal.show(idModal, modalConfig);
        })
        .catch((err) => console.error(err));
};

export default ModalAsyncContent;
