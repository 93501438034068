import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

import ContentSection from '@kit/ContentSection';

import './Questions.module.scss';

const Questions = ({ content }) => {
    const contentSection = content[0];

    return (
        <>
            {contentSection ? (
                <div className='questions'>
                    <Container className='questions__container'>
                        <ContentSection
                            subtitle={contentSection?.subtitle && contentSection.subtitle}
                            title={contentSection?.name && contentSection.name}
                            text={contentSection?.description && contentSection.description}
                            className='questions__section'
                        />
                        {contentSection?.posts && contentSection.posts?.length && (
                            <Row className='questions__list-wrapper'>
                                <Col xxl={12}>
                                    <Accordion defaultActiveKey='0' className='questions__list'>
                                        {contentSection.posts?.map((item) => (
                                            <Accordion.Item
                                                key={item.id}
                                                eventKey={`${item.id}`}
                                                className='questions__item'
                                            >
                                                <Accordion.Header className='questions__header'>
                                                    {item.title}
                                                </Accordion.Header>
                                                <Accordion.Body
                                                    className='questions__body'
                                                    dangerouslySetInnerHTML={{ __html: item.text }}
                                                />
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Questions;
