import React, { useState, forwardRef } from 'react';
import clsx from 'clsx';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import './DateCalendar.module.scss';

import { Form } from 'react-bootstrap';

import { DateCalendarProp } from '@store/types';

registerLocale('ru', ru);

const DateCalendar = forwardRef<DatePicker, DateCalendarProp>(
    (
        { selectedDate, label = null, onSelect, onChange, className, ...rest }: DateCalendarProp,
        ref
    ) => {
        const [startDate, setStartDate] = useState(selectedDate || new Date());

        return (
            <div className={clsx(['input-outer'], className)}>
                <div className={clsx('form-floating')}>
                    <Form.Group className='mb-2'>
                        {label && <Form.Label className={clsx('form-label')}>{label}</Form.Label>}
                        <DatePicker
                            selected={startDate}
                            className={clsx(['input-field'], ['form-control'])}
                            onSelect={(date) => onSelect(date)}
                            onChange={(date) => {
                                setStartDate(date);
                                onSelect(date);
                            }}
                            locale='ru'
                            dateFormat='P'
                            ref={ref}
                            autoComplete='off'
                            {...rest}
                        />
                    </Form.Group>
                </div>
            </div>
        );
    }
);

export default DateCalendar;
