import React, { useState } from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';

import ContactsLine from '@components/ContactsLine';
import Modals from '@components/Modals';
import Navigation from '@components/Navigation';

import Logo from '@kit/Logo';

import './Header.module.scss';

type HeaderProp = {
    fone?: boolean;
};

const Header = ({ fone = false }: HeaderProp) => {
    const [mobilePlumbMenu, setMobilePlumbMenu] = useState<boolean>(false);
    const [show, setShow] = useState(false);

    const handlerFone = () => {
        document.documentElement.classList.toggle('menu--show');
        setMobilePlumbMenu(false);
        setShow(false);
    };

    return (
        <header id='header' className='header'>
            <Container className='header__container'>
                {mobilePlumbMenu && <div onClick={handlerFone} className='header__plumb-mobile' />}
                <Row className={clsx(['header__row'])}>
                    <Col xs={8} md={4} lg='auto' xl='auto'>
                        <Logo />
                    </Col>
                    <Col xs={2} sm={1} md={4} lg xl>
                        <Navigation
                            show={show}
                            setShow={setShow}
                            setMobilePlumbMenu={setMobilePlumbMenu}
                        />
                    </Col>
                    <Col xs={2} md={4} lg='auto' xl='auto'>
                        <ContactsLine backCall={false} />
                    </Col>
                    <Col xs={12} lg={2} xl='auto'>
                        <div className='header__column-right'>
                            <button
                                className='btn btn-primary fast-order'
                                children='Быстрое бронирование'
                                data-module='FastOrder'
                                onClick={(e) =>
                                    Modals({
                                        item: e.currentTarget
                                    })
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
