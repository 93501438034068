import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { clearLink } from '@helpers/clearLink';
import { getImage } from '@helpers/getImage';

import './ServicesItem.module.scss';

const ServicesItem = ({ service, hidden = [] }) => {
    const { link, title } = service;
    const image = getImage(service);

    return (
        <div className='services__item'>
            {link && (
                <Link className='services__item-link' to={clearLink(link)}>
                    <div className='services__item-image'>
                        {image && <LazyLoadImage src={image} alt={title?.rendered} />}
                    </div>
                    <div className='services__item-title'>{title?.rendered}</div>
                </Link>
            )}
            {hidden.length ? (
                <div className='services__item-tooltip'>
                    <ul>
                        {hidden.map((item) => (
                            <li key={item.id}>
                                <Link to={clearLink(item.link)}>
                                    • <span>{item.title.rendered}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default ServicesItem;
