const config = {
    title: 'Меню',
    breadcrumbsDefault: {
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': '/',
                    name: 'Главная'
                }
            },
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': '/menu/',
                    name: 'Меню'
                }
            }
        ]
    },
    resolutions: {
        xs: 12,
        sm: 6,
        lg: 4
    }
};

export default config;
