import React from 'react';
import image from './preloader.svg';
import './Preloader.module.scss';

const Preloader = () => (
    <div className='preloader'>
        <img className='preloader__image' src={image} alt='' />
    </div>
);

export default Preloader;
