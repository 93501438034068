import React from 'react';
import clsx from 'clsx';

import { baseConfig } from '@store/baseConfig';
const { SITE_SOCIALS } = baseConfig();

import './Socials.module.scss';

const Socials = ({ socials = [] }) => {
    const socialsArray = socials.length
        ? SITE_SOCIALS.filter((item) => socials.includes(item.icon))
        : SITE_SOCIALS;

    return (
        <div className='socials'>
            <ul className={clsx(['socials__list', 'justify-content-end'])}>
                {socialsArray.map((item, index) => (
                    <li
                        key={index}
                        className={clsx(['socials__item', `socials__item--${item.icon}`])}
                    >
                        <a className='socials__link' href={item.href}>
                            <span
                                className={clsx(['socials__icon', 'icon', `icon-${item.icon}`])}
                            />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Socials;
