const config = {
    title: 'Статьи',
    breadcrumbsDefault: {
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': '/',
                    name: 'Главная'
                }
            },
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': '/articles/',
                    name: 'Статьи'
                }
            }
        ]
    },
    content: {
        text: ``
    },
    right: {
        resolutions: {
            xxl: 12
        }
    },
    resolutions: {
        xs: 12,
        md: 6,
        lg: 4
    }
};

export default config;
