import { Provider } from 'react-redux';
import store from '@store/store';

const LoaderReactAsync = (
    loadComponentFunction: Function,
    root: HTMLElement,
    props: Object = {}
): Promise<void> => {
    return Promise.all([import('react'), import('react-dom'), loadComponentFunction()])
        .then(
            ([
                { default: React },
                {
                    default: { hydrate }
                },
                { default: Component }
            ]) => {
                hydrate(
                    <Provider store={store}>
                        <Component {...props} />
                    </Provider>,
                    root
                );
            }
        )
        .catch((err) => console.error(err));
};

export default LoaderReactAsync;
