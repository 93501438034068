import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';

import Page from '@containers/Page';
import Layout from '@kit/Layout';

import Maps from '@components/Maps';
import ContactsItem from '@components/ContactsItem';
import Socials from '@components/Socials';

import contacts from './ContactsConfig';

import './Contacts.module.scss';

const Contacts = () => {
    const { title, list, breadcrumbsDefault } = contacts;

    const breadcrumbs = {
        breadcrumbsDefault
    };

    return (
        <Page>
            <Layout title={title} breadcrumbs={breadcrumbs} />
            <Container className='contacts-page'>
                <Row>
                    <Col xxl={8}>
                        {list.length && (
                            <div
                                className={clsx([
                                    'contacts__list',
                                    'contacts__list--vertical',
                                    'contacts__list--page'
                                ])}
                            >
                                {list.map((contact, index) => (
                                    <ContactsItem key={index} contact={contact} />
                                ))}
                            </div>
                        )}
                    </Col>
                    <Col xxl={4}>
                        <Socials />
                    </Col>
                </Row>
            </Container>
            <Maps className='contacts-page__map' />
        </Page>
    );
};

export default Contacts;
