import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Navigation from '@components/Navigation';
import ContactsLine from '@components/ContactsLine';
import Socials from '@components/Socials';
import Modals from '@components/Modals';

import { baseConfig } from '@store/baseConfig';

import Logo from '@kit/Logo';

import './Footer.module.scss';

const Footer = () => {
    const { SITE_NAME, SITE_COPYRIGHT } = baseConfig();

    return (
        <footer id='footer' className='footer'>
            <Container className='footer__container'>
                <Row className='footer__top'>
                    <Col xs={6} md={4} lg={3}>
                        <Logo />
                    </Col>
                    <Col xs={6} md={4} lg={6}>
                        <ContactsLine backCall={false} />
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        <div className='footer__column-right'>
                            <button
                                className='btn btn-primary fast-order'
                                children='Быстрое бронирование'
                                data-module='FastOrder'
                                onClick={(e) =>
                                    Modals({
                                        item: e.currentTarget
                                    })
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='footer__middle'>
                    <Col className='footer__middle-col' xs={8} sm={9}>
                        <Navigation switcher={false} />
                    </Col>
                    <Col className='footer__middle-col' xs={4} sm={3}>
                        <Socials />
                    </Col>
                </Row>
                <Row className='footer__bottom'>
                    <Col className='copyright' xs={12} md={6}>
                        {`© 2021 «${SITE_NAME}» - ${SITE_COPYRIGHT}`}
                    </Col>
                    <Col className='creator' xs={12} md={6}>
                        <a
                            target='_blank'
                            href='http://vasilev-dv.ru'
                            className='footer__creator-link'
                        >
                            Создание сайта в СПб
                        </a>{' '}
                        - DV-Studio
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
