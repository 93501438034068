import React from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';

import './StaticText.module.scss';

type StaticTextProp = {
    children?: any;
    className?: string;
};

const StaticText = ({ children, className }: StaticTextProp) => {
    return (
        <Container className={clsx(['content-page__text', className && className])}>
            <div
                className='content-page__text-wrapper'
                dangerouslySetInnerHTML={{ __html: children }}
            />
        </Container>
    );
};

export default StaticText;
