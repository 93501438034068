import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { clearLink } from '@helpers/clearLink';
import { getHtmlDate } from '@helpers/getHtmlDate';
import { getImage } from '@helpers/getImage';

import './ArticlesItem.module.scss';

const ArticlesItem = ({ article }) => {
    const image = getImage(article);

    return (
        <Col>
            <Card className='articles__item'>
                <Link className='articles__link' to={clearLink(article.link)}>
                    <Card.Subtitle
                        className='articles__date'
                        dangerouslySetInnerHTML={{ __html: getHtmlDate(article.date) }}
                    ></Card.Subtitle>
                    <div className='articles__image-wrapper'>
                        {image ? (
                            <LazyLoadImage className='articles__image' src={image} alt='' />
                        ) : (
                            <LazyLoadImage
                                className='articles__image'
                                src='http://dummyimage.com/424x283/c0c0c0'
                                alt=''
                            />
                        )}
                    </div>
                    <Card.Body className='articles__body'>
                        <Card.Title
                            className='articles__title'
                            dangerouslySetInnerHTML={{ __html: article?.title?.rendered }}
                        />
                        <Card.Text
                            className='articles__text'
                            dangerouslySetInnerHTML={{ __html: article?.content?.rendered }}
                        />
                    </Card.Body>
                </Link>
            </Card>
        </Col>
    );
};

export default ArticlesItem;
