const items = [
    {
        id: 1,
        icon: 'map',
        title: `Во всех <br>районах <br>города`
    },
    {
        id: 2,
        icon: 'menu',
        title: `Традиционное <br>поминальное <br>меню`
    },
    {
        id: 3,
        icon: 'table',
        title: `Специализированные <br>залы`
    },
    {
        id: 4,
        icon: 'kitchen',
        title: `Своя кухня`
    }
];

export default items;
