import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { AxiosResponse } from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import EmailValidator from 'email-validator';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { baseConfig } from '@store/baseConfig';
import { RootState } from '@store/store';
import FormaterPrice from '@helpers/FormaterPrice';

import NonceApi from '@api/Nonce';

import CheckboxLabel from '@kit/CheckboxLabel';
import Input from '@kit/Input';
import InputPhone from '@kit/InputPhone';
import DateCalendar from '@kit/DateCalendar';

import { calculateResult } from '@containers/Calculator/components/CalculatorForm';

import './Form.module.scss';

export type FormProp = {
    image?: any;
    title?: string;
    fields?: Array<string>;
    classNameCheckLabel?: string;
    idCheckLabel?: string;
    className?: string;
    action?: string;
    buttonInner?: string | JSX.Element;
};

export type FormComponentProp = {
    props?: FormProp;
    PromiseApi?: (data: any) => Promise<AxiosResponse<any>>;
    onSuccess?: any;
    onError?: any;
    error?: string | JSX.Element;
    store?: boolean;
    nonce?: number;
    page?: string;
};

const trapSpacesForRequiredFields = (value) => !!value?.trim();

const translateDate = (value) => {
    const date = new Date(value);
    const datePoint =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '.' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '.' +
        date.getFullYear();

    return datePoint;
};

const PromisePost = (nonce: number) =>
    new Promise((resolve, reject) => {
        NonceApi.get({ id: nonce })
            .then(({ data }) => {
                if (Object.keys(data).length) {
                    resolve({
                        loading: false,
                        data
                    });
                } else {
                    reject({
                        loading: false
                    });
                }

                reject({
                    loading: false
                });
            })
            .catch((err) => reject(err));
    });

const Forms = ({
    props,
    PromiseApi,
    onSuccess,
    onError,
    error,
    store = false,
    nonce = null
}: FormComponentProp) => {
    const {
        image = null,
        title,
        classNameCheckLabel = '',
        className = '',
        fields = ['name', 'email', 'phone'],
        idCheckLabel = 'formBasicCheckbox',
        buttonInner = 'Отправить',
        action,
        ...others
    } = props;
    const {
        handleSubmit,
        register,
        control,
        formState: { errors }
    } = useForm();
    const [phoneIsComplete, setPhoneIsComplete] = useState<boolean>(false);
    const [dateIsComplete, setDateIsComplete] = useState<boolean>(true);
    const [phone, setPhone] = useState<string>(null);
    const [dateValue, setDateValue] = useState<string>(translateDate(new Date()));
    const [nonceCode, setNonceCode] = useState([]);
    const { SITE_PRIMARY_LINK } = baseConfig();
    const calculate = useSelector((state: RootState) => state.calculate);
    const calculateValue = useSelector((state: RootState) => state.calculateValue);

    const onSubmit = async (data) => {
        if (store) {
            data['total'] = FormaterPrice(calculateResult(calculate));
            data = { ...data, ...calculateValue };
        }

        if (others['id']) {
            data['id'] = others['id'];
        }

        data['_wpnonce'] = nonceCode['nonce'];
        data['phone'] = phone;
        data['date_event'] = dateValue;
        data['action'] = action;

        try {
            await PromiseApi(data);
            onSuccess();
        } catch (err) {
            onError();
        }
    };

    useEffect(() => {
        if (nonce) {
            PromisePost(nonce)
                .then(({ data }) => setNonceCode(data))
                .catch((err) => console.error(err));
        }
    }, [nonce]);

    return (
        <Container className={clsx(['form-outer', className && className])} {...others}>
            <Row className='form-wrapper'>
                {image && (
                    <Col xs={6}>
                        <LazyLoadImage
                            className='form__image'
                            src={image}
                            alt=''
                            effect='blur'
                            threshold={500}
                        />
                    </Col>
                )}

                <Col xs={12} md={image ? 6 : 12} className='form__body'>
                    {title && <div className='form__title'>{title}</div>}

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {fields.includes('name') ? (
                            <Input
                                className='form__input mb-2'
                                label='ФИО'
                                error={errors.name && errors.name.message}
                                {...register('name', {
                                    validate: (value) =>
                                        trapSpacesForRequiredFields(value) ||
                                        'Поле обязательно к заполнению'
                                })}
                            />
                        ) : (
                            ''
                        )}

                        {fields.includes('email') ? (
                            <Input
                                className='form__input mb-2'
                                label='E-mail'
                                error={errors.email && errors.email.message}
                                {...register('email', {
                                    validate: (value) =>
                                        EmailValidator.validate(value) || 'Введите E-mail'
                                })}
                            />
                        ) : (
                            ''
                        )}

                        {fields.includes('phone') ? (
                            <Controller
                                name={'phone'}
                                control={control}
                                defaultValue=''
                                rules={{
                                    validate: () =>
                                        phoneIsComplete || 'Неверный формат номера телефона'
                                }}
                                render={({ field, fieldState }) => (
                                    <InputPhone
                                        label='Телефон'
                                        mask='+7 (000) 000-00-00'
                                        placeholder='+7 (___) ___-__-__'
                                        value={phone}
                                        error={
                                            fieldState.error &&
                                            !phoneIsComplete &&
                                            fieldState.error.message
                                        }
                                        className={clsx(
                                            ['input-field'],
                                            ['form-control'],
                                            fieldState.error &&
                                                !phoneIsComplete &&
                                                fieldState.error.message &&
                                                'is-invalid'
                                        )}
                                        onAccept={(value, mask) => {
                                            setPhoneIsComplete(mask.masked.isComplete);
                                            setPhone(value);
                                        }}
                                    />
                                )}
                            />
                        ) : (
                            ''
                        )}

                        {fields.includes('date_event') || fields.includes('person') ? (
                            <Row>
                                {fields.includes('date_event') ? (
                                    <Col xs={6}>
                                        <Controller
                                            name={'date_event'}
                                            control={control}
                                            defaultValue={dateValue}
                                            rules={{
                                                validate: () =>
                                                    dateIsComplete || 'Неверный формат даты'
                                            }}
                                            render={({ field, fieldState }) => (
                                                <DateCalendar
                                                    className={clsx(
                                                        ['form__input mb-2'],
                                                        fieldState.error &&
                                                            !phoneIsComplete &&
                                                            fieldState.error.message &&
                                                            'is-invalid'
                                                    )}
                                                    name='date_event'
                                                    label='Дата мероприятия'
                                                    placeholder=''
                                                    onSelect={(value) => {
                                                        setDateValue(translateDate(value));
                                                        setDateIsComplete(true);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}

                                {fields.includes('person') ? (
                                    <Col xs={6}>
                                        <Input
                                            className='form__input mb-2'
                                            label='Кол-во человек'
                                            type='number'
                                            defaultValue='1'
                                            min={1}
                                            max={99}
                                            error={errors.person && errors.person.message}
                                            {...register('person', {
                                                validate: (value) =>
                                                    trapSpacesForRequiredFields(value) ||
                                                    'Поле обязательно к заполнению'
                                            })}
                                        />
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        ) : (
                            ''
                        )}

                        <CheckboxLabel
                            id={idCheckLabel}
                            className={classNameCheckLabel}
                            defaultChecked={true}
                            error={errors.agree && errors.agree.message}
                            {...register('agree', {
                                required: 'Поле обязательно для заполнения'
                            })}
                            label={
                                <span>
                                    Принимаю&nbsp;
                                    <a target='_blank' href={SITE_PRIMARY_LINK}>
                                        соглашение об обработке персональных данных
                                    </a>
                                </span>
                            }
                        />

                        <button
                            className='btn btn-primary btn-lg form__button'
                            type='submit'
                            children={buttonInner}
                        />

                        {error ? error : ''}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Forms;
