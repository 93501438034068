import React from 'react';
import clsx from 'clsx';
import './Hamburger.module.scss';

const Hamburger = ({ handler, className = '' }) => {
    return (
        <div onClick={handler} className={clsx(['hamburger', className && className])}>
            <span className='hamburger__line'></span>
            <span className='hamburger__line'></span>
            <span className='hamburger__line'></span>
        </div>
    );
};

export default Hamburger;
