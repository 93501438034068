import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioboxLabel from '@kit/RadioboxLabel';
import Select from '@kit/Select';
import { RootState } from '@store/store';
import FormaterPrice from '@helpers/FormaterPrice';

import { baseConfig } from '@store/baseConfig';
import {
    setCountDebt,
    setCountLenders,
    setCountDocs,
    setCountChildren,
    setCountDeals,
    setCountProperty
} from '@store/calculateReducer';

import {
    setValueDebt,
    setValueLenders,
    setValueDocs,
    setValueChildren,
    setValueDeals,
    setValueProperty
} from '@store/calculateValueReducer';

import config from './CalculatorFormConfig';

import './CalculatorForm.module.scss';

export const calculateResult = (object) =>
    Object.values(object).reduce((summa: number, value: number) => summa + value);

const CalculatorForm = () => {
    const inputEl = useRef(null);
    const {
        SITE_CALCULATOR: {
            individual: { deposit, after }
        }
    } = baseConfig();
    const {
        optionsDebt,
        options,
        optionsProperty,
        lenders,
        selectors: { docs, children, deals }
    } = config;

    const dispatch = useDispatch();
    const calculate = useSelector((state: RootState) => state.calculate);

    const getCurrentTextOption = (options) => options[options.selectedIndex].text;

    const summaSelect = (value, summa) => (!Number(value) ? 0 : Number(value) * summa);

    const inputHandler = (event, type = null) => {
        const { min, max } = inputEl.current;
        let value = Number(inputEl.current.value);

        if (type) {
            if (type == -1) {
                value -= 1;
            } else {
                value += 1;
            }
        }

        if (value < Number(min)) {
            inputEl.current.value = min;
        } else if (value > Number(max)) {
            inputEl.current.value = max;
        } else {
            inputEl.current.value = value;
        }

        dispatch(setCountLenders(inputEl.current.value * lenders));
        dispatch(setValueLenders(inputEl.current.value));
    };

    useEffect(() => {
        dispatch(setCountDebt(optionsDebt[0].summa));
        dispatch(setValueDebt(optionsDebt[0].title));
    }, [dispatch, optionsDebt]);

    return (
        <div className='calculator-form'>
            <div className='calculator-form__row calculator-form__row--center'>
                <div className='calculator-form__row-text'>
                    "Закон о несостоятельности (банкротстве)" №127-ФЗ от 26.10.2002г. <br />
                    В расчёте учтены все расходы на процедуру, включая вознаграждение (п.3 ст.20.6)
                    финансового управляющего в делах о банкротстве <br />
                    физ.лиц - {FormaterPrice(deposit)}
                </div>
            </div>

            <div className='calculator-form__row'>
                <div className='calculator-form__row-header'>Сумма долга</div>
                <div className='calculator-form__row-component'>
                    {optionsDebt.map((option, index) => (
                        <RadioboxLabel
                            key={index}
                            id={`debtOptions_${option.id}`}
                            name='debt'
                            defaultChecked={index === 0 ? true : false}
                            label={option.title}
                            onChange={() => {
                                dispatch(setCountDebt(option.summa));
                                dispatch(setValueDebt(option.title));
                            }}
                        />
                    ))}
                </div>
            </div>

            <div className='calculator-form__row calculator-form__row--switcher'>
                <div className='calculator-form__row-header'>Количество кредиторов</div>
                <div className='calculator-form__row-component'>
                    <div className='calculator-form__switcher'>
                        <button
                            className='calculator-form__switcher-btn'
                            onClick={(e) => inputHandler(e, -1)}
                        >
                            -
                        </button>
                        <input
                            type='number'
                            name='lenders'
                            max={20}
                            min={0}
                            defaultValue={0}
                            ref={inputEl}
                            className='calculator-form__switcher-input'
                            onChange={(e) => inputHandler(e)}
                        />
                        <button
                            className='calculator-form__switcher-btn'
                            onClick={(e) => inputHandler(e, 1)}
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>

            <div className='calculator-form__row'>
                <div className='calculator-form__row-header'>
                    Нужен сбор документов для процедуры
                </div>
                <div className='calculator-form__row-text'>
                    Рекомендуем осуществлять сбор документов самостоятельно. Это не займет много сил
                    и времени, а экономия существенная.
                </div>
                <div className='calculator-form__row-component'>
                    <Select
                        options={options}
                        name='docs'
                        defaultValue='Выберите вариант'
                        onChange={(e) => {
                            const textCurrent = getCurrentTextOption(e.target.options);
                            dispatch(setCountDocs(summaSelect(e.target.value, docs)));
                            dispatch(
                                setValueDocs(e.target.value == textCurrent ? '' : textCurrent)
                            );
                        }}
                    />
                </div>
            </div>

            <div className='calculator-form__row'>
                <div className='calculator-form__row-header'>Есть ли несовершеннолетние дети?</div>
                <div className='calculator-form__row-component'>
                    <Select
                        options={options}
                        name='children'
                        defaultValue='Выберите вариант'
                        onChange={(e) => {
                            const textCurrent = getCurrentTextOption(e.target.options);
                            dispatch(setCountChildren(summaSelect(e.target.value, children)));
                            dispatch(
                                setValueChildren(e.target.value == textCurrent ? '' : textCurrent)
                            );
                        }}
                    />
                </div>
            </div>

            <div className='calculator-form__row'>
                <div className='calculator-form__row-header'>Наличие имущества в собственности</div>
                <div className='calculator-form__row-text'>
                    Есть ли у вас какое-либо имущество, кроме единственного жилья? Например, жилой
                    дом, квартира, автомобиль, земельные участки, доли в ООО.
                </div>
                <div className='calculator-form__row-component'>
                    <Select
                        options={optionsProperty}
                        name='property'
                        defaultValue='Выберите вариант'
                        onChange={(e) => {
                            const textCurrent = getCurrentTextOption(e.target.options);
                            dispatch(setCountProperty(optionsProperty[e.target.value]?.summa ?? 0));
                            dispatch(
                                setValueProperty(e.target.value == textCurrent ? '' : textCurrent)
                            );
                        }}
                    />
                </div>
            </div>

            <div className='calculator-form__row'>
                <div className='calculator-form__row-header'>
                    Сделки с имуществом за последние 3 года
                </div>
                <div className='calculator-form__row-text'>
                    Совершались ли вами сделки по отчуждению ценного имущества?
                </div>
                <div className='calculator-form__row-component'>
                    <Select
                        options={options}
                        name='deals'
                        defaultValue='Выберите вариант'
                        onChange={(e) => {
                            const textCurrent = getCurrentTextOption(e.target.options);
                            dispatch(setCountDeals(summaSelect(e.target.value, deals)));
                            dispatch(
                                setValueDeals(e.target.value == textCurrent ? '' : textCurrent)
                            );
                        }}
                    />
                </div>
            </div>

            <div className='calculator-form__row calculator-form__row--total'>
                <div className='calculator-form__row-header'>Стоимость процедуры банкротства</div>
                <div className='calculator-form__total'>
                    ВСЕГО:
                    <div className='calculator-form__summa'>
                        {FormaterPrice(calculateResult(calculate))}
                    </div>
                </div>
            </div>

            <div className='calculator-form__row'>
                <div className='calculator-form__row-header'>
                    Калькулятор расчёта показывает итоговую фиксированную сумму расходов, которая
                    распределяется:
                </div>
                <div className='calculator-form__row-text'>
                    <ul>
                        <li>
                            {FormaterPrice(deposit)} &mdash; вносится на депозит Арбитражного суда
                            до запуска процедуры.
                        </li>
                        <li>
                            {FormaterPrice(after)} &mdash; авансируются публикации в процедуре после
                            приёма заявления о банкротстве.
                        </li>
                        <li>
                            Оставшаяся сумма выплачивается в рассрочку в течение 10 месяцев равными
                            платежами.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CalculatorForm;
