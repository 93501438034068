import clsx from 'clsx';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Map, YMaps, ObjectManager } from 'react-yandex-maps';

import { baseConfig } from '@store/baseConfig';

import styles from './MapObject.module.scss';
import { createCollectionMap, setCenterAndZoomMap } from './helpers';
import point from './point.svg';
import pointHover from './point_hover.svg';

export interface MapProps extends React.HTMLAttributes<HTMLDivElement> {
    data: any;
    setLoading: (boolean) => void;
    onCurrentValue: (any) => void;
    setMap?: React.Dispatch<React.SetStateAction<boolean>>;
    currentValue?: string;
    loading?: boolean;
    show?: boolean;
    city: string;
}

const MapObject = ({
    data = {},
    loading,
    setLoading,
    setMap,
    show = true,
    city = 'Санкт-Петербург',
    onCurrentValue,
    currentValue
}: MapProps) => {
    const [ymaps, setYmaps] = useState(null);
    const [center, setCenter] = React.useState([59.95, 30.31]);
    const [mapObjectManager, setMapObjectManager] = useState(null);
    const [mapObject, setMapObject] = useState(null);
    const { SITE_MOBILE_SIZE } = baseConfig();

    const mapState = useMemo(
        () => ({
            center,
            zoom: 12,
            controls: ['zoomControl']
        }),
        [center]
    );

    const collection = useMemo(() => {
        return ymaps ? createCollectionMap(ymaps, data) : {};
    }, [ymaps, data]);

    const setCenterMap = useCallback(
        (mapObjectManager, objectId: string = null) => {
            setCenterAndZoomMap({
                ymaps,
                mapObjectManager,
                mapObject,
                collection,
                objectId
            });
        },
        [ymaps, mapObject, collection]
    );

    const hoverObjectManager = (objectId) => {
        mapObjectManager &&
            mapObjectManager.objects.setObjectOptions(objectId, {
                iconImageHref: pointHover,
                iconImageSize: [60, 79],
                iconImageOffset: [-28, -79]
            });
    };

    const defaultObjectManager = (objectId) => {
        mapObjectManager &&
            mapObjectManager.objects.setObjectOptions(objectId, {
                iconImageHref: point,
                iconImageSize: [40, 53],
                iconImageOffset: [-18, -53]
            });
    };

    const changeValueHandler = (objectId) => {
        // onCurrentValue &&
        //     onCurrentValue((prevState) => {
        //         defaultObjectManager(prevState);
        //         hoverObjectManager(objectId);
        //         onCurrentValue(objectId);
        //     });
    };

    useEffect(() => {
        if (mapObjectManager && currentValue && mapObject) {
            changeValueHandler(currentValue);
            setCenterMap(mapObjectManager, currentValue);
        } else {
            changeValueHandler(null);
        }
    }, [currentValue, mapObjectManager, setCenterMap, mapObject]);

    return (
        <YMaps
            query={{
                load: 'util.bounds,geocode',
                apikey: '2924354d-77ea-4446-8589-82d82f39470d'
            }}
        >
            <div
                className={clsx(
                    styles['map-wrapper'],
                    SITE_MOBILE_SIZE && styles['map-wrapper--mobile']
                )}
            >
                {loading && <div className='loading' />}
                <Map
                    onLoad={(ymaps) => {
                        setYmaps(ymaps);
                        setLoading(false);
                        ymaps.geocode(city).then((res) => {
                            !currentValue &&
                                setCenter(res.geoObjects.get(0).geometry.getCoordinates());
                            setMap(true);
                        });
                    }}
                    className={styles.map}
                    state={mapState}
                    modules={['control.ZoomControl', 'layout.PieChart', 'templateLayoutFactory']}
                    instanceRef={(ref) => {
                        setMapObject(ref);
                    }}
                >
                    {ymaps !== null ? (
                        <>
                            <ObjectManager
                                objects={{
                                    openBalloonOnClick: true
                                }}
                                options={{
                                    clusterize: true,
                                    gridSize: 64,
                                    maxZoom: 15,
                                    maxAnimationZoomDifference: Infinity,
                                    clusterIconLayout: 'default#pieChart',
                                    clusterIconPieChartRadius: 20,
                                    clusterIconPieChartCoreRadius: 14,
                                    clusterIconPieChartStrokeWidth: 1,
                                    hideIconOnBalloonOpen: false
                                }}
                                features={{
                                    type: 'FeatureCollection',
                                    features: collection
                                }}
                                modules={[
                                    'objectManager.addon.objectsBalloon',
                                    'objectManager.addon.clustersBalloon'
                                ]}
                                instanceRef={(ref) => {
                                    if (ref && ref !== mapObjectManager) {
                                        setMapObjectManager(ref);
                                    }
                                    !currentValue && setCenterMap(ref);
                                }}
                                onMouseEnter={(e) => {
                                    const objectId = e.get('objectId');

                                    if (currentValue && currentValue === objectId) return;
                                    hoverObjectManager(objectId);
                                }}
                                onMouseLeave={(e) => {
                                    const objectId = e.get('objectId');

                                    if (currentValue && currentValue === objectId) return;
                                    defaultObjectManager(objectId);
                                }}
                                onClick={(e) => {
                                    const objectId = e.get('objectId');
                                    const objectState = mapObjectManager.getObjectState(objectId);

                                    if (objectState?.found && objectState?.isShown) {
                                        if (!objectState.isClustered) {
                                            changeValueHandler(objectId);
                                        }
                                    }
                                }}
                            />
                        </>
                    ) : null}
                </Map>
            </div>
        </YMaps>
    );
};

export default MapObject;
