import React, { useState, useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Tabs, Tab, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import RangeSlider from '@kit/RangeSlider';

import NonceApi from '@api/Nonce';
import AttributesApi from '@api/Attributes';

import ProductItem from '@components/ProductItem';
import { FormComponentProp } from '@components/Form';
import MapOutposts from '@components/MapOutposts';

import { baseConfig } from '@store/baseConfig';

import './SearchList.module.scss';

const PromisePost = (promise, params?: {}) =>
    new Promise((resolve, reject) => {
        promise(params)
            .then(({ data }) => {
                if (Object.keys(data).length) {
                    resolve({
                        loading: false,
                        data
                    });
                } else {
                    reject({
                        loading: false
                    });
                }

                reject({
                    loading: false
                });
            })
            .catch((err) => reject(err));
    });

const range = { min: 0, max: 7000 };
const connect = { min_price: 1000, max_price: 3000 };
const lastTabState = { tab: 'list' };

type AttributesProps = {
    cemetery?: any;
    district?: any;
    metro?: any;
    product_cat?: any;
};

interface FormProps extends FormComponentProp {
    onScrollEffect?: () => void;
}

const SearchList = forwardRef<HTMLDivElement, FormProps>((props: FormProps, ref) => {
    const {
        PromiseApi,
        onSuccess,
        onError,
        nonce = 451,
        onScrollEffect,
        page = 'main',
        ...other
    } = props;
    const [tab, setTab] = useState('list');
    const [map, setMap] = useState(false);
    const [loading, setLoading] = useState(true);
    const [nonceCode, setNonceCode] = useState([]);
    const [products, setProducts] = useState([]);
    const [attributes, setAttributes] = useState<AttributesProps>({});
    const { SITE_MOBILE_SIZE } = baseConfig();

    const { handleSubmit, setValue, unregister } = useForm();

    const mapOptions = (items) => {
        if (!items) return { label: 'Загрузка данных...', value: '' };

        return items.map((option) => {
            return {
                label: option.name,
                value: option.term_id
            };
        });
    };

    const selectHandler = (value, type) => {
        const array = [];
        Object.values(value).map(({ value }) => array.push(value));

        array.length ? setValue(type, array) : unregister(type);
    };

    const onSubmit = async (data) => {
        data['_wpnonce'] = nonceCode['nonce'];
        data['action'] = 'filter_search_places';
        setLoading(true);

        try {
            const result = await PromiseApi(data);
            const datas = result?.data?.data;
            setProducts(datas.items);
            SITE_MOBILE_SIZE && switcherHtml(lastTabState.tab);
            setLoading(false);
            onSuccess && onSuccess();
            onScrollEffect && onScrollEffect();
        } catch (err) {
            onError && onError();
            setLoading(false);
        }
    };

    const switcherHtml = (k) => {
        let tabSet = k;

        if (tabSet === 'filters') {
            tabSet = lastTabState.tab;
            document.documentElement.classList.add('open-filters');
        } else {
            document.documentElement.classList.remove('open-filters');
        }

        setTab(tabSet);
    };

    useEffect(() => {
        PromisePost(NonceApi.get, { id: nonce })
            .then(({ data }) => setNonceCode(data))
            .then(() => {
                try {
                    AttributesApi.get().then(({ data }) => {
                        setAttributes(data);
                    });
                } catch (err) {
                    console.error(err);
                }
            })
            .then(async () => {
                try {
                    const result = await PromiseApi({
                        _wpnonce: nonce,
                        action: 'filter_search_places',
                        ...connect
                    });
                    const datas = result?.data?.data;
                    setProducts(datas.items);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    console.error(err);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <div className='search-line' ref={ref} {...other}>
            <Container className='search-line__container'>
                <Row className={clsx(['search-line__row'])}>
                    <Col xs={12} lg={3} xl={3}>
                        <div className={clsx(['advanced-search'])}>
                            <Row className='advanced-search__row'>
                                <Col className='col advanced-search__col'>
                                    <div className='advanced-search__title'>
                                        Поиск зала{' '}
                                        <div
                                            className={clsx([
                                                'icon',
                                                'icon-cross',
                                                'advanced-search__close'
                                            ])}
                                            onClick={() =>
                                                SITE_MOBILE_SIZE && switcherHtml(lastTabState.tab)
                                            }
                                        />
                                    </div>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group
                                            className={clsx(['advanced-search__group', 'mb-3'])}
                                        >
                                            <Form.Label>По цене</Form.Label>
                                            <RangeSlider
                                                range={range}
                                                connect={connect}
                                                className='mb-3'
                                                onChange={(value) => {
                                                    Object.keys(value).map((key) => {
                                                        setValue(key, value[key]);
                                                    });
                                                }}
                                            />
                                        </Form.Group>
                                        {!attributes?.cemetery?.errors ? (
                                            <Form.Group
                                                className={clsx(['advanced-search__group', 'mb-3'])}
                                            >
                                                <Form.Label htmlFor='district'>
                                                    По кладбищу
                                                </Form.Label>
                                                <Select
                                                    options={mapOptions(attributes.cemetery)}
                                                    id='cemetery'
                                                    name='cemetery'
                                                    className='select-wrapper'
                                                    placeholder='Выберите кладбище'
                                                    classNamePrefix='select-custom'
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isMulti
                                                    onChange={(value) =>
                                                        selectHandler(value, 'cemetery')
                                                    }
                                                />
                                            </Form.Group>
                                        ) : (
                                            ''
                                        )}
                                        {!attributes?.district?.errors ? (
                                            <Form.Group
                                                className={clsx(['advanced-search__group', 'mb-3'])}
                                            >
                                                <Form.Label htmlFor='district'>
                                                    По району
                                                </Form.Label>
                                                <Select
                                                    options={mapOptions(attributes.district)}
                                                    id='district'
                                                    name='district'
                                                    className='select-wrapper'
                                                    placeholder='Выберите район'
                                                    classNamePrefix='select-custom'
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isMulti
                                                    onChange={(value) =>
                                                        selectHandler(value, 'district')
                                                    }
                                                />
                                            </Form.Group>
                                        ) : (
                                            ''
                                        )}
                                        {!attributes?.metro?.errors ? (
                                            <Form.Group
                                                className={clsx(['advanced-search__group', 'mb-3'])}
                                            >
                                                <Form.Label htmlFor='metro'>По метро</Form.Label>
                                                <Select
                                                    options={mapOptions(attributes.metro)}
                                                    id='metro'
                                                    name='metro'
                                                    className='select-wrapper'
                                                    placeholder='Выберите метро'
                                                    classNamePrefix='select-custom'
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isMulti
                                                    onChange={(value) =>
                                                        selectHandler(value, 'metro')
                                                    }
                                                />
                                            </Form.Group>
                                        ) : (
                                            ''
                                        )}
                                        {!attributes?.product_cat?.errors ? (
                                            <Form.Group
                                                className={clsx(['advanced-search__group', 'mb-3'])}
                                            >
                                                <Form.Label htmlFor='product_cat'>
                                                    По типу
                                                </Form.Label>
                                                <Select
                                                    options={mapOptions(attributes.product_cat)}
                                                    id='product_cat'
                                                    name='product_cat'
                                                    className='select-wrapper'
                                                    placeholder='Выберите тип'
                                                    classNamePrefix='select-custom'
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isMulti
                                                    onChange={(value) =>
                                                        selectHandler(value, 'product_cat')
                                                    }
                                                />
                                            </Form.Group>
                                        ) : (
                                            ''
                                        )}
                                        <Form.Group className='advanced-search__group advanced-search__group--button mb-3'>
                                            <Button
                                                className='advanced-search__button'
                                                type='submit'
                                                variant='primary'
                                            >
                                                Найти
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} lg={9} xl={9}>
                        <div className='search-result'>
                            {loading ? <div className='loading'></div> : ''}
                            <Tabs
                                id='controlled-tab-example'
                                activeKey={tab}
                                onSelect={(k) => {
                                    lastTabState['tab'] = tab;
                                    switcherHtml(k);
                                }}
                                className='tabs mb-3'
                            >
                                <Tab
                                    eventKey='list'
                                    title={
                                        <span className={clsx(['tabs__item', 'icon', 'icon-tile'])}>
                                            Списком
                                        </span>
                                    }
                                >
                                    <Row
                                        className={clsx([
                                            'search-result__block',
                                            'search-result__block--products',
                                            'g-3',
                                            tab === 'list' && 'search-result__block--show',
                                            page !== 'main' && 'search-result__block--full'
                                        ])}
                                    >
                                        {products ? (
                                            products.length ? (
                                                products?.map((product, index) => (
                                                    <Col xs={12} sm={6} xl={4}>
                                                        <ProductItem
                                                            key={product.id}
                                                            product={product}
                                                        />
                                                    </Col>
                                                ))
                                            ) : (
                                                <Col xs={12} sm={6} xl={4}>
                                                    <div>
                                                        Не найдено ни одного места по выбранным
                                                        параметрам
                                                    </div>
                                                </Col>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </Row>
                                </Tab>
                                <Tab
                                    eventKey='map'
                                    title={
                                        <span
                                            className={clsx(['tabs__item', 'icon', 'icon-address'])}
                                        >
                                            На карте
                                        </span>
                                    }
                                >
                                    <div
                                        className={clsx([
                                            'search-result__block',
                                            'search-result__block--map',
                                            tab === 'map' && 'search-result__block--show'
                                        ])}
                                    >
                                        {(tab === 'map' && !map) || map ? (
                                            <MapOutposts
                                                setMap={setMap}
                                                data={products}
                                                city={'Санкт-Петербург'}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </Tab>
                                <Tab
                                    eventKey='filters'
                                    className='nav-item--filters'
                                    title={
                                        <span
                                            className={clsx(['tabs__item', 'icon', 'icon-filters'])}
                                        />
                                    }
                                />
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
});

export default SearchList;
