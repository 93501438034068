import BalloonItemTemplate from '../BalloonItemTemplate';
import { clearLink } from '@helpers/clearLink';
import pointIcon from './point.svg';

export const createCollectionMap = (ymaps, points) => {
    return points.map(function (point) {
        return {
            id: point['id'],
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: point.values?.coords && point.values.coords[0].split(',')
            },
            options: {
                iconColor: `#5790b9`,
                iconLayout: 'default#image',
                iconImageHref: pointIcon,
                iconImageSize: [40, 53],
                iconImageOffset: [-18, -53],
                hideIconOnBalloonOpen: false,
                groupByCoordinates: false,
                balloonMinWidth: 280,
                balloonMaxWidth: 316,
                balloonContentLayout: ymaps.templateLayoutFactory.createClass(BalloonItemTemplate)
            },
            properties: {
                clusterCaption: point.title,
                id: point.id,
                name: point.title,
                image: point.image,
                url: clearLink(point.url),
                address: point.values.address[0],
                regular_price: point.values._regular_price[0],
                persons: point.values?.persons ? point.values.persons[0] : null,
                working_hours: point.values?.working_hours ? point.values.working_hours[0] : null
            }
        };
    });
};

export const setCenterAndZoomMap = ({
    ymaps,
    mapObjectManager,
    mapObject,
    collection,
    objectId = null
}) => {
    if (ymaps && mapObjectManager) {
        setTimeout(() => {
            if (collection.length <= 1) {
                if (collection.length === 1) {
                    mapObject.setCenter(collection[0].geometry.coordinates, 18);
                }
            } else {
                if (objectId) {
                    const objectCoordinates =
                        mapObjectManager.objects.getById(objectId).geometry.coordinates;
                    mapObject.setCenter(objectCoordinates, 17);
                } else {
                    const result = ymaps.util.bounds.getCenterAndZoom(
                        mapObjectManager.getBounds(),
                        mapObject.container.getSize()
                    );
                    let zoom = result.zoom < 10 ? 10 : result.zoom;
                    zoom = zoom > 18 ? 18 : zoom;
                    mapObject.setCenter(result.center, zoom);
                }
            }
        }, 10);
    }
};
