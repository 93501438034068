const items = {
    content: {
        title: 'Меню',
        link: {
            name: 'Все меню',
            href: '/menu/'
        }
    }
};

export default items;
