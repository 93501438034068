import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';

import Slider from '@components/Slider';

import { baseConfig } from '@store/baseConfig';

import config from './PrimarySliderConfig';

import './PrimarySlider.module.scss';

type ConfigProps = {
    id: number;
    icon: any;
    title: string;
};

type PrimarySliderProps = {
    cats?: any;
    onScrollEffect?: () => void;
};

const PrimarySlider = ({ cats, onScrollEffect }: PrimarySliderProps) => {
    const contentSection = cats[0];

    const items: ConfigProps[] = config;
    const { SITE_MOBILE_SIZE } = baseConfig();

    const settings = {
        lazyload: true,
        nav: true,
        loop: true,
        items: 1,
        controls: false,
        speed: 1000,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        navPosition: 'bottom'
    };

    const buttonHandler = () => {
        if (SITE_MOBILE_SIZE) {
            document.documentElement.classList.add('open-filters');
        } else {
            onScrollEffect && onScrollEffect();
        }
    };

    const loadingImage =
        'data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
    return (
        <div className='slider__wrapper'>
            <div className='slider'>
                {contentSection && contentSection?.posts ? (
                    <Slider settings={settings}>
                        {contentSection.posts?.map((item) => (
                            <div key={item.id}>
                                {(item.text || item['name_html']) && (
                                    <Container>
                                        <Row className='align-items-center'>
                                            <Col xl={6}>
                                                <div className='slider__description'>
                                                    <div className='slider__body'>
                                                        <div
                                                            className='slider__title'
                                                            dangerouslySetInnerHTML={{
                                                                __html: item['name_html']
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='slider__button'>
                                                        <button
                                                            className={clsx([
                                                                'btn btn-primary btn-lg',
                                                                'slider__btn'
                                                            ])}
                                                            type='button'
                                                            onClick={buttonHandler}
                                                        >
                                                            Поиск зала
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className='features'>
                                                    <div className='features__list'>
                                                        {items?.length ? (
                                                            <>
                                                                {items.map((item) => (
                                                                    <div
                                                                        key={item.id}
                                                                        className='features__item'
                                                                    >
                                                                        <div
                                                                            className={clsx([
                                                                                'features__icon',
                                                                                'icon',
                                                                                item?.icon &&
                                                                                    `icon-${item.icon}`
                                                                            ])}
                                                                        />
                                                                        <div
                                                                            className='features__name'
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.title
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                                <img
                                    className={`tns-lazy-img`}
                                    src={loadingImage}
                                    data-src={item.image}
                                    alt=''
                                    width='1920'
                                    height='757'
                                />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default PrimarySlider;
