import React from 'react';
import clsx from 'clsx';
import { YMaps, Map, Placemark, MapState } from 'react-yandex-maps';

import baloon from './baloon.svg';

import './MapContact.scss';

type MapPlacemark = {
    coords?: any;
    hintContent?: string;
    balloonContent?: string;
};

type MapProps = {
    center: number[];
    zoom: number;
    placemark?: MapPlacemark;
};

type MapContactProp = {
    className?: string;
    props?: MapProps;
};

const MapContact = ({ className, props }: MapContactProp) => {
    const {
        center,
        zoom,
        placemark: { coords, hintContent, balloonContent }
    } = props;
    const mapState: MapState = {
        center,
        zoom,
        controls: []
    };

    return (
        <YMaps>
            <Map defaultState={mapState} className={clsx(className && className)}>
                <Placemark
                    defaultGeometry={coords}
                    properties={{
                        hintContent,
                        balloonContent
                    }}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: baloon,
                        iconImageSize: [30, 42],
                        iconImageOffset: [-3, -42]
                    }}
                />
            </Map>
        </YMaps>
    );
};

export default MapContact;
