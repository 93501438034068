import { AjaxApi } from './Request';
import { baseConfig } from '@store/baseConfig';

const { SITE_URL } = baseConfig();

const Forms = {
    send: (data?) => {
        const formData = new FormData();
        if (data) {
            Object.keys(data).map((key) => {
                formData.append(key, data[key]);
            });
        }
        return AjaxApi.post(`${SITE_URL}/wp-admin/admin-ajax.php`, formData);
    }
};

export default Forms;
