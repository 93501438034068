import ModalAsyncContent from '@components/ModalAsyncContent';
import './ModalAsyncLoader.scss';

const ModalAsyncLoader = ({ item, modules }) => {
    const moduleDataset = item?.dataset;
    const module = moduleDataset && modules[moduleDataset?.module];

    module &&
        ModalAsyncContent({
            module,
            moduleDataset
        });
};

export default ModalAsyncLoader;
