import { ClientApi } from './Request';
import objectToGetString from '@helpers/objectToGetString';

const Pages = {
    get: (data) =>
        ClientApi.get(
            '/pages/?_embed' + (objectToGetString(data) ? '&' + objectToGetString(data) : '')
        )
};

export default Pages;
