import React from 'react';
import clsx from 'clsx';

import { Container, Row, Col } from 'react-bootstrap';

//import Posts from '@api/Posts';

import Slider from '@components/Slider';
import MapContact from '@components/MapContact';
import Modals from '@components/Modals';

//import { BreadcrumbsProp } from '@store/types';

// import Layout from '@kit/Layout';
// import Preloader from '@kit/Preloader';

import './ProductContent.module.scss';

const ProductContent = ({ datas }) => {
    const galleries = [];

    if (datas?.image) {
        galleries.push({
            original: [datas?.image],
            large: [datas?.image]
        });
    }

    if (datas?.galleries) {
        galleries.push(...datas?.galleries);
    }

    const settings = {
        lazyload: true,
        nav: true,
        loop: false,
        items: 1,
        controls: false,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        navContainer: '#customize-thumbnails',
        navAsThumbnails: true,
        navPosition: 'bottom'
    };

    const district = datas?.district || [];
    const metro = datas?.metro || [];
    const values = datas?.values || {};

    return (
        <Container className='product-page'>
            <Row className={clsx(['galleries-wrapper', 'g-4'])}>
                <Col xs={12} md={6} lg={4}>
                    <div className='characters'>
                        {values?.phone ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>
                                        {values?.phone?.length > 1 ? 'Телефоны' : 'Телефон'}:
                                    </div>
                                    <div className='characters__value'>
                                        {values?.phone?.join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {values?.address ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>Адрес:</div>
                                    <div className='characters__value'>
                                        {values?.address?.join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {values?.working_hours ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>Рабочие часы:</div>
                                    <div className='characters__value'>
                                        {values?.working_hours?.join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {district.length ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>Район:</div>
                                    <div className='characters__value'>
                                        {datas.district?.map((item) => item.name).join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {metro.length ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>Метро:</div>
                                    <div className='characters__value'>
                                        {datas.metro?.map((item) => item.name).join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {values?.places ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>Залы:</div>
                                    <div className='characters__value'>
                                        {values?.places?.join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {values?.average_price ? (
                            <div className='characters__item'>
                                <div className='characters__body'>
                                    <div className='characters__label'>Средний чек:</div>
                                    <div className='characters__value'>
                                        {values?.average_price?.join(', ')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className='product-page__order'>
                            <button
                                className='btn btn-primary btn-lg fast-order'
                                children='Забронировать'
                                data-module='FastOrder'
                                data-id={datas.id}
                                data-title={datas.title}
                                onClick={(e) =>
                                    Modals({
                                        item: e.currentTarget
                                    })
                                }
                            />
                        </div>
                        {datas?.values?.coords && (
                            <MapContact
                                className='characters__map'
                                props={{
                                    center: datas?.values?.coords[0]
                                        .split(',')
                                        .map((coords) => Number(coords)),
                                    zoom: 14,
                                    placemark: {
                                        coords: datas?.values?.coords[0]
                                            .split(',')
                                            .map((coords) => Number(coords)),
                                        hintContent: datas.title,
                                        balloonContent: datas.title
                                    }
                                }}
                            />
                        )}
                    </div>
                </Col>
                {galleries.length ? (
                    <Col xs={12} md={6} lg={8}>
                        <Slider settings={settings} className='galleries-large'>
                            {galleries.map((image, index) => (
                                <img key={index} src={image?.large[0]} alt='' />
                            ))}
                        </Slider>
                        <div className='galleries-thumbs' id='customize-thumbnails'>
                            {galleries.map((image, index) => (
                                <img
                                    className='galleries-thumbs__image'
                                    key={index}
                                    src={image?.large[0]}
                                    alt=''
                                />
                            ))}
                        </div>
                    </Col>
                ) : (
                    ''
                )}
            </Row>
            <Row className={clsx(['description-wrapper', 'g-4'])}>
                <Col xs={12}>
                    <div
                        className='static-text'
                        dangerouslySetInnerHTML={{ __html: datas?.text }}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default ProductContent;
