import { baseConfig } from '@store/baseConfig';

const { SITE_ADDRESS, SITE_PHONE, SITE_PHONE_WHATSAPP, SITE_EMAIL, SITE_MODS } = baseConfig();

const config = {
    title: 'Контакты',
    breadcrumbsDefault: {
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': '/',
                    name: 'Главная'
                }
            },
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': '/contacts/',
                    name: 'Контакты'
                }
            }
        ]
    },
    list: [
        {
            icon: 'address',
            text: SITE_ADDRESS
        },
        {
            icon: 'phone',
            text: `
                ${SITE_PHONE}<br />
                ${SITE_PHONE_WHATSAPP} (Тел. / WhatsApp)
            `
        },
        {
            icon: 'mail',
            link: {
                text: SITE_EMAIL,
                href: `mailto:${SITE_EMAIL}`
            }
        },
        {
            icon: 'clock',
            link: {
                text: SITE_MODS,
                href: 'javascript:void(0)'
            }
        }
    ]
};

export default config;
