import ModalAsyncLoader from '@components/ModalAsyncLoader';
import './Modals.module.scss';

const Modals = ({ item }) => {
    ModalAsyncLoader({
        item,
        modules: {
            BackCall: () => import('@components/BackCall'),
            FastOrder: () => import('@components/FastOrder')
        }
    });
};

export default Modals;
