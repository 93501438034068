import { ClientApi } from './Request';
import { baseConfig } from '@store/baseConfig';

const { SITE_URL } = baseConfig();

const Attributes = {
    get: () => ClientApi.get(`${SITE_URL}/wp-json/myplugin/v1/attributes/`)
};

export default Attributes;
