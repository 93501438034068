import React from 'react';
import { Link } from 'react-router-dom';

import './Logo.module.scss';

const Logo = () => {
    return (
        <div className='logo'>
            <Link exact className='logo__link' to='/'></Link>
        </div>
    );
};

export default Logo;
