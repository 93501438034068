import { baseConfig } from '@store/baseConfig';

const clearLink = (link, folder?) => {
    const { SITE_URL } = baseConfig();

    return folder
        ? link.replace(SITE_URL, '')
        : link.replace('/wordpress/', '/').replace('wordpress', '').replace(SITE_URL, '');
};

export { clearLink };
