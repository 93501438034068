import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';

import Menu from '@api/Menu';
import ContentSection, { ContentSectionProp } from '@kit/ContentSection';

import MenuItem from '@components/MenuItem';
import Slider from '@components/Slider';

import { baseConfig } from '@store/baseConfig';

import config from './MenuListConfig';

import './MenuList.module.scss';

const MenuList = ({ limit = null, slider = false, defaultMenus = [] }) => {
    const content: ContentSectionProp = config?.content;
    const settings = {
        lazyload: true,
        nav: true,
        loop: false,
        items: 1,
        controls: false,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        navPosition: 'bottom',
        responsive: {
            992: {
                items: 3
            },
            575: {
                items: 2,
                gutter: 30
            },
            0: {
                items: 1
            }
        }
    };

    const [menus, setMenus] = useState(defaultMenus);

    useEffect(() => {
        const { SITE_MENUS } = baseConfig();

        if (!menus.length) {
            Menu.get({ id: SITE_MENUS }).then(({ data }) => {
                setMenus(data);
            });
        }
    }, [menus]);

    return (
        <>
            {menus.length ? (
                <div className='menus'>
                    <Container className='menus__container'>
                        {content && (
                            <ContentSection
                                subtitle={content.subtitle}
                                title={content.title}
                                text={content?.text && content.text}
                                link={content?.link && content.link}
                                className='menus__section'
                            />
                        )}

                        {!limit ? (
                            <>
                                {slider ? (
                                    <div className={clsx(['menus__list-wrapper', 'g-4'])}>
                                        <Slider settings={settings}>
                                            {menus.map((item, index) => (
                                                <MenuItem key={index} menu={item} />
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    <Row className={clsx(['menus__list-wrapper', 'g-4'])}>
                                        {menus.map((item, index) => (
                                            <Col xs={12} md={6} lg={4}>
                                                <MenuItem key={index} menu={item} />
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </>
                        ) : (
                            <Row className={clsx(['menus__list-wrapper', 'g-4'])}>
                                {menus.slice(0, limit).map((item, index) => (
                                    <Col xs={12} md={6} lg={4}>
                                        <MenuItem key={index} menu={item} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Container>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default MenuList;
