import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { Form, FormCheck } from 'react-bootstrap';

import './CheckboxLabel.module.scss';

export interface CheckboxLabelProps extends React.HTMLAttributes<HTMLDivElement> {
    name?: string;
    message?: string;
    error?: string | boolean;
    success?: string | boolean;
    defaultChecked?: boolean;
    id?: string;
    label: React.ReactNode;
    className?: string;
}

const CheckboxLabel = forwardRef<HTMLInputElement, CheckboxLabelProps>((props, ref) => {
    const {
        name,
        className,
        error,
        success = '',
        defaultChecked,
        id,
        label,
        message,
        ...others
    } = props;
    const errorMessage = typeof error === 'string' ? error : '';
    const successMessage = typeof success === 'string' ? success : '';

    return (
        <div className={clsx(['form-checkbox-label', className && className])} {...others}>
            <Form.Group className='mb-2' controlId={id}>
                <FormCheck className='form-check-wrapper'>
                    <FormCheck.Label>
                        <FormCheck.Input
                            isInvalid
                            className='form-check-input'
                            type='checkbox'
                            ref={ref}
                            name={name}
                            id={id}
                            defaultChecked={defaultChecked}
                            {...others}
                        />
                        {(error || success) && (
                            <Form.Text
                                className={clsx(
                                    ['text-muted'],
                                    error && 'invalid-feedback',
                                    success && 'valid-feedback'
                                )}
                            >
                                {error ? errorMessage : success ? successMessage : message}
                            </Form.Text>
                        )}
                        <span className={clsx('icon', 'form-check-view')}></span>
                        {label}
                    </FormCheck.Label>
                </FormCheck>
            </Form.Group>
        </div>
    );
});

export default CheckboxLabel;
