import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Posts from '@api/Posts';
import ArticleItem from '@components/ArticlesItem';
import { baseConfig } from '@store/baseConfig';

import Layout from '@kit/Layout';

import config from './ArticlesConfig';

import './Articles.module.scss';

const Articles = ({ defaultArticles = [] }) => {
    const { title, resolutions, breadcrumbsDefault } = config;
    const [articles, setArticles] = useState(defaultArticles);
    const breadcrumbs = {
        breadcrumbsDefault
    };

    useEffect(() => {
        const { SITE_ARTICLES } = baseConfig();

        Posts.get({ categories: SITE_ARTICLES }).then(({ data }) => {
            setArticles(data);
        });
    }, []);

    return (
        <>
            <Layout title={title} breadcrumbs={breadcrumbs} />
            <Container className='articles-page'>
                <Row className='articles-page__wrapper g-4'>
                    {articles && articles.length
                        ? articles.map((article, index) => (
                              <Col className='' {...resolutions}>
                                  <ArticleItem key={index} article={article} />
                              </Col>
                          ))
                        : ''}
                </Row>
            </Container>
        </>
    );
};

export default Articles;
