import React from 'react';
import clsx from 'clsx';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { clearLink } from '@helpers/clearLink';
import './ProductItem.module.scss';

const ProductItem = ({ product }) => {
    const {
        url,
        image,
        title,
        metro,
        values: { address, persons }
    } = product;

    return (
        <Card className='product__item'>
            <Link className='product__link product__link' to={clearLink(url)}>
                <div className='product__image-wrapper'>
                    {image ? (
                        <LazyLoadImage className='product__image' src={image} alt='' />
                    ) : (
                        <LazyLoadImage
                            className='product__image'
                            src='http://dummyimage.com/316x200/f5f5f5'
                            alt=''
                        />
                    )}
                </div>
                <Card.Body className='product__body'>
                    <Card.Title className='product__title'>{title}</Card.Title>
                    {metro ? (
                        <div className={clsx('product__row', 'product__metro')}>
                            <span
                                className={clsx('product__row-icon', 'icon', 'icon-metro')}
                            ></span>
                            {metro?.map((item) => item.name).join(', ')}
                        </div>
                    ) : (
                        ''
                    )}
                    {address ? (
                        <Card.Subtitle className={clsx('product__row', 'product__address')}>
                            <span
                                className={clsx('product__row-icon', 'icon', 'icon-address')}
                            ></span>
                            {address[0]}
                        </Card.Subtitle>
                    ) : (
                        ''
                    )}
                    {persons ? (
                        <div className={clsx('product__row', 'product__persons')}>
                            <span
                                className={clsx('product__row-icon', 'icon', 'icon-person')}
                            ></span>
                            {persons[0]}
                        </div>
                    ) : (
                        ''
                    )}
                </Card.Body>
            </Link>
        </Card>
    );
};

export default ProductItem;
