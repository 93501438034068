import React from 'react';
import clsx from 'clsx';
import { Container, Alert } from 'react-bootstrap';

import { BreadcrumbsProp } from '@store/types';

import Breadcrumbs from '@kit/Breadcrumbs';
import StaticText from '@kit/StaticText';

import './Layout.module.scss';

type LayoutProp = {
    title?: string;
    breadcrumbs?: BreadcrumbsProp;
    children?: any;
    className?: string;
    image?: string;
    error?: string;
    types?: string;
};

const Layout = ({
    title,
    breadcrumbs,
    children,
    className,
    image = null,
    error,
    types = 'post'
}: LayoutProp) => {
    let styles = {};

    if (image) {
        styles = {
            backgroundImage: `url(${image})`
        };
    }

    return (
        <div className={clsx(['content-page', className && className])}>
            {(title || breadcrumbs) && (
                <div className='content-page__header' style={styles}>
                    <Container className='content-page__header-wrapper'>
                        {breadcrumbs && (breadcrumbs?.id || breadcrumbs?.breadcrumbsDefault) && (
                            <Breadcrumbs types={types} {...breadcrumbs} />
                        )}
                        {title && <h1 className='content-page__title'>{title}</h1>}
                    </Container>
                </div>
            )}
            <div className='content-page__wrapper'>
                {children && <StaticText>{children}</StaticText>}
                {error && (
                    <Container className='error-page'>
                        <Alert variant='danger'>{error}</Alert>
                    </Container>
                )}
            </div>
        </div>
    );
};

export default Layout;
