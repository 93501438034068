import { ClientApi } from './Request';
import objectToGetString from '@helpers/objectToGetString';

const Posts = {
    get: (data) =>
        ClientApi.get(
            '/posts/?_embed' + (objectToGetString(data) ? '&' + objectToGetString(data) : '')
        )
};

export default Posts;
