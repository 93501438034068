import React from 'react';

import Forms from '@api/Forms';

import Page from '@containers/Page';

import SearchList from '@components/SearchList';

import Layout from '@kit/Layout';

import config from './ProductsConfig';

import './Products.module.scss';

const Products = ({ defaultProducts = [] }) => {
    const { title, breadcrumbsDefault } = config;
    const breadcrumbs = {
        breadcrumbsDefault
    };

    return (
        <Page>
            <Layout title={title} breadcrumbs={breadcrumbs} />
            <SearchList page='product' PromiseApi={Forms.send} />
        </Page>
    );
};

export default Products;
