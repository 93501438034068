import React, { useEffect, useState } from 'react';

import Page from '@containers/Page';

import Pages from '@api/Pages';

import { BreadcrumbsProp } from '@store/types';

import Layout from '@kit/Layout';
import Preloader from '@kit/Preloader';

import './About.module.scss';

type PageProp = {
    loading: boolean;
    data?: any;
    error?: string;
};

const StaticPage = (props) => {
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsProp>({
        type: 'post',
        id: null
    });
    const [post, setPost] = useState<PageProp>({
        loading: true,
        data: {},
        error: null
    });

    const { loading, data, error } = { ...post };

    useEffect(() => {
        Pages.get({ slug: 'about' })
            .then(({ data }) => {
                if (Object.keys(data).length) {
                    setPost((prevState) => ({
                        ...prevState,
                        loading: false,
                        data
                    }));
                    setBreadcrumbs({
                        type: 'post',
                        id: data[0].id
                    });
                } else {
                    setPost((prevState) => ({
                        ...prevState,
                        loading: false,
                        error: 'Такая запись не найдена'
                    }));
                }
            })
            .catch((err) => {
                setPost((prevState) => ({
                    ...prevState,
                    loading: false,
                    error: `Ошибка: ${err.message}`
                }));
            });
    }, []);

    return (
        <Page>
            {loading && <Preloader />}
            <Layout
                title={data[0]?.title?.rendered ?? null}
                breadcrumbs={breadcrumbs}
                error={error}
            >
                {Object.keys(data).length ? data[0]?.content?.rendered : ''}
            </Layout>
        </Page>
    );
};

export default StaticPage;
