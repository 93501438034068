import React from 'react';
import clsx from 'clsx';

import './ContactsItem.module.scss';

type ContactLinkProp = {
    href: string;
    text: string;
};

type ContactsProps = {
    icon?: string;
    text?: string;
    link?: ContactLinkProp;
};

type ContactsItemProp = {
    contact: ContactsProps;
};

const ContactsItem = ({ contact }: ContactsItemProp) => {
    const { icon, link, text } = contact;
    return (
        <div className={clsx(['contacts__item', icon && `contacts__item--${icon}`])}>
            {icon && <span className={clsx(['contacts__icon', 'icon', `icon-${icon}`])} />}
            <div className='contacts__text'>
                {link ? (
                    <a className='contacts__link' href={link?.href}>
                        {link?.text}
                    </a>
                ) : (
                    <div
                        className='contacts__text-inner'
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
            </div>
        </div>
    );
};

export default ContactsItem;
