import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';

import Posts from '@api/Posts';
import { baseConfig } from '@store/baseConfig';
import { ServicesProp } from '@store/types';

import ContentSection, { ContentSectionProp } from '@kit/ContentSection';

import ServicesItem from '@components/ServicesItem';

import './Services.module.scss';

import plumb from './plumb.jpg';

const hiddenItem = {
    _embedded: {
        'wp:featuredmedia': [
            {
                source_url: plumb
            }
        ]
    },
    link: 'javascript:void(0)',
    title: {
        rendered: 'Прочие услуги'
    }
};

const Services = ({
    config,
    className = '',
    defaultServices = [],
    cats = {},
    limit = null
}: ServicesProp) => {
    const content: ContentSectionProp = config.content;
    const { right } = config;

    const [services, setServices] = useState(defaultServices);

    useEffect(() => {
        const { SITE_SERVICES } = baseConfig();

        if (!services.length) {
            Posts.get({ categories: SITE_SERVICES }).then(({ data }) => {
                setServices(data);
            });
        }
    }, [services]);

    return (
        <div className={clsx(['services', className && className])}>
            <Container className='services__container'>
                <Row className={clsx(['services__row'])}>
                    <Col className='services__right' {...right?.resolutions}>
                        <div className='services__content'>
                            {content ? (
                                <ContentSection
                                    subtitle={content?.subtitle}
                                    title={content?.title}
                                    text={content?.text && content.text}
                                    className={clsx(['services__content-section'])}
                                />
                            ) : (
                                ''
                            )}
                            {services?.length ? (
                                <div className='services__list'>
                                    {limit ? (
                                        <>
                                            {services.slice(0, limit).map((item, index) => (
                                                <ServicesItem key={item.id} service={item} />
                                            ))}
                                            {services.length > limit ? (
                                                <ServicesItem
                                                    service={hiddenItem}
                                                    hidden={services.slice(limit, services.length)}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {services.map((item, index) => (
                                                <ServicesItem key={item.id} service={item} />
                                            ))}
                                        </>
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Services;
