const objectToGetString = (data) => {
    const pairs = [];
    for (const key in data) {
        if (Object.getOwnPropertyDescriptor(data, key)) {
            pairs.push(key + '=' + encodeURIComponent(data[key]));
        }
    }
    return pairs.join('&');
};

export default objectToGetString;
