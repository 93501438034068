import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import wNumb from 'wnumb';
import Nouislider from 'nouislider-react';
import { Row, Col } from 'react-bootstrap';

import Input from '@kit/Input';

import 'nouislider/distribute/nouislider.css';
import './RangeSlider.module.scss';

const RangeSlider = ({
    onChange,
    step = 100,
    range = { min: 0, max: 7000 },
    connect = { min_price: 1000, max_price: 3000 },
    className = ''
}) => {
    const [price, setPrice] = useState(connect);
    const handleChange = (e) => {
        setPrice((prevState) => {
            return { ...prevState, ...{ [e.target.name]: e.target.value } };
        });
    };
    const handleSlider = (e) => {
        setPrice((prevState) => {
            return { ...prevState, ...{ min_price: e[0], max_price: e[1] } };
        });
    };

    useEffect(() => {
        onChange && onChange(price);
    }, [price, onChange]);

    return (
        <div className='prices'>
            <Nouislider
                step={step}
                range={range}
                start={[price.min_price, price.max_price]}
                connect
                onChange={handleSlider}
                className={clsx([className && className])}
                format={wNumb({
                    decimals: 0,
                    thousand: ''
                })}
            />
            <Row className='range-inputs'>
                <Col>
                    <Input
                        className={clsx(['form__input', 'form__input--range'])}
                        name='min_price'
                        type='number'
                        label='от'
                        step={step}
                        value={price.min_price}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={1} className='range__line'>
                    &mdash;
                </Col>
                <Col>
                    <Input
                        className={clsx(['form__input', 'form__input--range'])}
                        name='max_price'
                        type='number'
                        label='до'
                        step={step}
                        value={price.max_price}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RangeSlider;
