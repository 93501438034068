import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';
import Page from '@containers/Page';

import MenuApi from '@api/Menu';
import Layout from '@kit/Layout';
import { baseConfig } from '@store/baseConfig';

import MenuItem from '@components/MenuItem';

import documents from './MenuConfig';
import config from './MenuConfig';

import './Menu.module.scss';

const Menu = () => {
    const { title, resolutions } = documents;
    const { breadcrumbsDefault } = config;
    const breadcrumbs = {
        breadcrumbsDefault
    };

    const [menu, setMenu] = useState([]);

    useEffect(() => {
        const { SITE_MENUS } = baseConfig();

        MenuApi.get({ id: SITE_MENUS })
            .then(({ data }) => {
                if (Object.keys(data).length) {
                    setMenu(data);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <Page>
            <Layout title={title} breadcrumbs={breadcrumbs} />
            <Container className='menu-page'>
                {menu.length ? (
                    <Row className={clsx(['menu-page__list'])}>
                        {menu.map((item, index) => (
                            <Col key={index} className='menu-page__item' {...resolutions}>
                                <MenuItem menu={item} />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    ''
                )}
            </Container>
        </Page>
    );
};

export default Menu;
