import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import ScrollToTop from '@helpers/ScrollToTop';

import Header from '@components/Header';
import Footer from '@components/Footer';
import Routes from '../../routers';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.module.scss';

const App = () => {
    return (
        <Router>
            <ScrollToTop>
                <Header />
                <Switch>{renderRoutes(Routes)}</Switch>
                <Footer />
            </ScrollToTop>
        </Router>
    );
};

export default App;
