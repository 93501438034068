import React from 'react';
import { Container, Alert } from 'react-bootstrap';
import './FormSuccess.module.scss';

type FormSuccessProp = {
    success?: string | JSX.Element;
};

const FormSuccess = ({ success = 'Ваш запрос успешно отправлен!' }: FormSuccessProp) => (
    <Container className='success-page'>
        <Alert variant='success'>{success}</Alert>
    </Container>
);

export default FormSuccess;
