import React, { useState } from 'react';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LoaderReactAsync from '@helpers/LoaderReactAsync';

import { baseConfig } from '@store/baseConfig';

import desktop from './images/map_desktop.jpg';
import mobile from './images/map_mobile.jpg';

import './Maps.scss';

const Maps = ({ className = '', desktopImage = desktop, mobileImage = mobile }) => {
    const [loadedMap, setLoadedMap] = useState<boolean>(false);
    const {
        SITE_MAP: {
            center,
            zoom,
            placemark: { coords, hintContent, balloonContent }
        }
    } = baseConfig();

    const handlerMouseover = (e) => {
        if (!loadedMap) {
            try {
                LoaderReactAsync(() => import('@components/MapContact'), e.currentTarget, {
                    className: 'map__outer',
                    props: {
                        center,
                        zoom,
                        placemark: { coords, hintContent, balloonContent }
                    }
                }).then(() => setLoadedMap(true));
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <div className={clsx('map', className)}>
            <div
                className='map__wrapper'
                onTouchMove={handlerMouseover}
                onMouseOver={handlerMouseover}
            >
                <LazyLoadImage
                    className='map__image'
                    src={window.innerWidth < 992 ? mobileImage : desktopImage}
                    alt=''
                />
            </div>
        </div>
    );
};

export default Maps;
