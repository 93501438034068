import React, { useState } from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';
import Page from '@containers/Page';

import Layout from '@kit/Layout';

import FormsApi from '@api/Forms';
import Form from '@components/Form';
import FormSuccess from '@components/FormSuccess';
import FormError from '@components/FormError';
import ServicesCommon from '@components/Services';

import config from './ServicesConfig';

import './Services.module.scss';

const Services = () => {
    const { title, breadcrumbsDefault } = config;
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const breadcrumbs = {
        breadcrumbsDefault
    };
    const onSuccess = () => {
        setSuccess(true);
    };
    const onError = () => {
        setError(true);
    };

    return (
        <Page>
            <Layout title={title} breadcrumbs={breadcrumbs} />
            <div className='services-page'>
                <ServicesCommon config={config} className='services-page__wrapper' />
            </div>
            <Container fluid className={clsx(['services-page__form'])}>
                {!success ? (
                    <Form
                        onSuccess={onSuccess}
                        onError={onError}
                        PromiseApi={FormsApi.send}
                        props={config.form}
                        error={error && <FormError container={false} />}
                    />
                ) : (
                    <FormSuccess />
                )}
            </Container>
        </Page>
    );
};

export default Services;
