const items = {
    content: {
        title: 'Статьи',
        link: {
            name: 'Все статьи',
            href: '/articles/'
        }
    }
};

export default items;
