const phone = '+7 (812) 509–67–09';
const phoneLink = '+78125096709';
const phoneWhatsapp = '+7 (812) 509–67–09';
const phoneWhatsappLink = '+78125096709';
const email = 'antonkachurin28@mail.ru';
const emailForm = email;
const mods = `Ежедневно с 10:00 до 20:00, по предварительной записи`;
const addressFull = `194354, г.Санкт-Петербург, пр. Луначарского, д. 60, корп. 1 лит. Г`;
const addressFullText = '194354, г.Санкт-Петербург, пр. Луначарского, д. 60, корп. 1 лит. Г';
const addressShort = addressFullText;
const linkPrimary = '/about/privacy-policy/';
const mapCoords = [60.042119, 30.341659];
const mapCoordsCenter = [60.042119, 30.341659];
const mapZoom = 16;

const baseConfig = () => ({
    SITE_NAME: 'Светлая память',
    SITE_COPYRIGHT: 'Сеть поминальных залов. Все права защищены.',
    SITE_URL: 'https://pominki.spb.ru',
    SITE_PHONE: phone,
    SITE_PHONE_LINK: phoneLink,
    SITE_PHONE_WHATSAPP: phoneWhatsapp,
    SITE_PHONE_WHATSAPP_LINK: phoneWhatsappLink,
    SITE_EMAIL: email,
    SITE_EMAIL_FORM: emailForm,
    SITE_MODS: mods,
    SITE_ADDRESS: addressFull,
    SITE_ADDRESS_TEXT: addressFullText,
    SITE_ADDRESS_SHORT: addressShort,
    SITE_PRIMARY_LINK: linkPrimary,
    SITE_MOBILE_SIZE: window.innerWidth < 992 ? true : false,
    SITE_MAP: {
        center: window.innerWidth < 992 ? mapCoordsCenter : mapCoords,
        zoom: mapZoom,
        placemark: {
            coords: [60.042119, 30.341659],
            hintContent: 'Собственный значок метки',
            balloonContent: 'Это красивая метка'
        }
    },
    SITE_SOCIALS: [
        {
            icon: 'vk',
            href: '!#'
        },
        {
            icon: 'instagram',
            href: '!#'
        }
    ],
    SITE_MENUS: 63,
    SITE_ARTICLES: 19,
    SITE_QUESTIONS: 18,
    SITE_SERVICES: 3,
    SITE_INDEX_PAGE: 17,
    SITE_PRIMARY_SLIDER: 20,
    SITE_CALCULATOR: {
        individual: {
            deposit: 25000,
            after: 15000
        },
        entity: {}
    },
    SITE_CURRENCY: '₽'
});

export { baseConfig };
