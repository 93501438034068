import clsx from 'clsx';
import React, { useState } from 'react';

import { baseConfig } from '@store/baseConfig';

import styles from './MapOutposts.module.scss';
import MapObject from './components/MapObject';

interface MapProps {
    data?: any;
    city?: string;
    setMap?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapOutposts = ({ data, city = 'Санкт-Петербург', setMap }: MapProps) => {
    const [currentValue, setCurrentValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const { SITE_MOBILE_SIZE } = baseConfig();

    return (
        <div className={clsx(styles['map'], SITE_MOBILE_SIZE && styles['map--mobile'])}>
            <MapObject
                data={data}
                setLoading={setLoading}
                loading={loading}
                setMap={setMap}
                city={city}
                show={true}
                onCurrentValue={setCurrentValue}
                currentValue={currentValue}
            />
        </div>
    );
};

export default MapOutposts;
