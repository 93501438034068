import React from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { clearLink } from '@helpers/clearLink';

import './MenuItem.module.scss';

const MenuItem = ({ menu }) => {
    const lines = menu?.preview?.split('\r\n');

    const rows = lines.map((line) => {
        const pars = line?.split('|');
        return { key: pars[0], value: pars[1] };
    });

    return (
        <Col>
            <Card className='menus__item'>
                <Card.Body className='menus__body'>
                    <div
                        className='menus__title'
                        dangerouslySetInnerHTML={{ __html: menu?.title }}
                    />
                    <div className='menus__text'>
                        {rows.length &&
                            rows?.map((row) => (
                                <div className='menus__text-row'>
                                    <div className='menus__text-key'>
                                        <span>{row.key}</span>
                                    </div>
                                    <div className='menus__text-value'>{row.value}</div>
                                </div>
                            ))}
                    </div>
                </Card.Body>
                <Link className='menus__link' to={clearLink(menu.url)}>
                    <Button className='menus__button' variant='primary'>
                        Подробнее
                    </Button>
                </Link>
            </Card>
        </Col>
    );
};

export default MenuItem;
