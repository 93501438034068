import React from 'react';
import { Container, Alert } from 'react-bootstrap';
import './FormError.module.scss';

type FormErrorProp = {
    error?: string | JSX.Element;
    container?: boolean;
};

const FormError = ({
    error = 'Произошла ошибка! Попробуйте повторить позднее',
    container = true
}: FormErrorProp) => {
    const ContainerWrap = (props) => (container ? <Container {...props} /> : <div {...props} />);

    return (
        <ContainerWrap className='error-page'>
            <Alert variant='danger'>{error}</Alert>
        </ContainerWrap>
    );
};

export default FormError;
