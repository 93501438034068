import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import BreadcrumbsApi from '@api/Breadcrumbs';

import { clearLink } from '@helpers/clearLink';

import './Breadcrumbs.module.scss';

const Breadcrumbs = ({
    type = 'post',
    id = null,
    breadcrumbsDefault = null,
    className = '',
    types = 'post'
}) => {
    const [breadcrumbs, setBreadcrumbs] = useState(breadcrumbsDefault);

    useEffect(() => {
        if (!breadcrumbs) {
            BreadcrumbsApi.get({ type, id }).then(({ data }) => {
                setBreadcrumbs(data);
            });
        }
    }, [breadcrumbs, type, id]);

    return (
        <div className={clsx(['breadcrumbs-wrapper', className && className])}>
            {breadcrumbs && breadcrumbs?.itemListElement ? (
                <ul className='breadcrumbs__list'>
                    {breadcrumbs.itemListElement?.map((breadcrumb, index) => (
                        <li
                            property='itemListElement'
                            key={index}
                            className='breadcrumbs__item'
                            typeof={breadcrumb['@type']}
                        >
                            {index + 1 < breadcrumbs.itemListElement.length ? (
                                <>
                                    <Link
                                        className='breadcrumbs__link'
                                        to={
                                            index === 1 && types === 'product'
                                                ? '/places/'
                                                : clearLink(breadcrumb.item['@id'])
                                        }
                                        property='item'
                                        typeof='WebPage'
                                    >
                                        <span property='name' className='breadcrumbs__text'>
                                            {!index
                                                ? 'Главная'
                                                : types === 'product' && index === 1
                                                ? 'Каталог мест'
                                                : breadcrumb.item.name}
                                        </span>
                                        <span
                                            className={clsx([
                                                'breadcrumbs__icon',
                                                'icon',
                                                'icon-arrow-bottom'
                                            ])}
                                        ></span>
                                    </Link>
                                    <meta property='position' content={breadcrumb.position} />
                                </>
                            ) : (
                                <span
                                    property='itemListElement'
                                    typeof={breadcrumb['@type']}
                                    className={clsx([
                                        'breadcrumbs__text',
                                        'breadcrumbs__text--current'
                                    ])}
                                >
                                    <span property='name'>{breadcrumb.item.name}</span>
                                    <meta
                                        property='url'
                                        content={clearLink(breadcrumb.item['@id'])}
                                    />
                                    <meta property='position' content={breadcrumb.position} />
                                </span>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                ''
            )}
        </div>
    );
};

export default Breadcrumbs;
