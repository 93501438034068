import formImage from './form_image.jpg';

const config = {
    title: 'Услуги',
    breadcrumbsDefault: {
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': '/',
                    name: 'Legal Fox'
                }
            },
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': '/services/',
                    name: 'Услуги'
                }
            }
        ]
    },
    content: {
        text: `
            Готовы представлять Ваши интересы в арбитражных 
            судах различных инстанций при рассмотрении дел:
        `
    },
    right: {
        resolutions: {
            xxl: 12
        }
    },
    form: {
        image: formImage,
        title: 'Записаться на консультацию',
        fields: ['name', 'phone', 'agree']
    }
};

export default config;
