import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Page from '@containers/Page';

import Layout from '@kit/Layout';

import './NotFound.module.scss';

const NotFound = ({ staticContext = { status: 200 } }) => {
    staticContext.status = 404;

    return (
        <Page>
            <Layout title='Такой страницы нет' />
            <Container className='page-not-found'>
                <Row>
                    <Col>
                        Вернитесь на <a href='/'>главную страницу</a>
                        <br />
                        или воспользуйтесь <a href='/places/'>каталогом мест</a>.
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default NotFound;
