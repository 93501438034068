import './BalloonItemTemplate.scss';

const BalloonItemTemplate = `
    <div class="custom-balloon">
        <div class="header-balloon">
            {% if properties.image %}
            <div class="header-balloon__image">
                <img src="{{properties.image}}" alt="{{properties.name}}" />
            </div>
            {% endif %}
            <div class="header-balloon__body">
                <p title="{{properties.address}}" class="address">{{properties.address}}</p>
                <p title="{{properties.name}}" class="name">{{properties.name}}</p>
            </div>
        </div>
        <ul class="list info-list">
            {% if properties.regular_price %}
                <li class="list-item">
                    <p class="list-item__type">Стоимость</p>
                    <div>
                        <p class="list-item__value-flex">{{properties.regular_price}} ₽</p>
                    </div>
                </li>
            {% endif %}
            {% if properties.persons %}
                <li class="list-item">
                    <p class="list-item__type">Кол-во человек</p>
                    <div>
                        <p class="list-item__value-flex">{{properties.persons}}</p>
                    </div>
                </li>
            {% endif %}
            {% if properties.working_hours %}
                <li class="list-item">
                    <p class="list-item__type">Время работы</p>
                    <div>
                        <p class="list-item__value-flex">{{properties.working_hours}}</p>
                    </div>
                </li>
            {% endif %}
        </ul>
        <a class="btn btn-primary btn-sm button" href="{{properties.url}}" target="_blank">Подробнее</a>
    </div>`;

// <div class="buttons">
// <button class="buttons_btn buttons_btn-choose {% if activePoint === properties.id %}buttons_btn-choose_selected{% else %}{% endif %} btn btn-bs btn-base-default btn-text-size-bs" data-id="{{properties.id}}">{% if activePoint === properties.id %}Выбран{% else %}Выбрать{% endif %}</button>
// </div>

export default BalloonItemTemplate;
