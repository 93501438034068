import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';

import Posts from '@api/Posts';
import ContentSection, { ContentSectionProp } from '@kit/ContentSection';

import ArticleItem from '@components/ArticlesItem';
import Slider from '@components/Slider';

import { baseConfig } from '@store/baseConfig';

import config from './ArticlesConfig';

import './ArticlesList.module.scss';

const ArticlesList = ({ limit = null, slider = false, defaultArticles = [] }) => {
    const content: ContentSectionProp = config?.content;
    const settings = {
        lazyload: true,
        nav: true,
        loop: false,
        items: 1,
        controls: false,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        navPosition: 'bottom',
        responsive: {
            992: {
                items: 3
            },
            575: {
                items: 2,
                gutter: 30
            },
            0: {
                items: 1
            }
        }
    };

    const [articles, setArticles] = useState(defaultArticles);

    useEffect(() => {
        const { SITE_ARTICLES } = baseConfig();

        if (!articles.length) {
            Posts.get({ categories: SITE_ARTICLES }).then(({ data }) => {
                setArticles(data);
            });
        }
    }, [articles]);

    return (
        <>
            {articles.length ? (
                <div className='articles'>
                    <Container className='articles__container'>
                        {content && (
                            <ContentSection
                                subtitle={content.subtitle}
                                title={content.title}
                                text={content?.text && content.text}
                                link={content?.link && content.link}
                                className='articles__section'
                            />
                        )}

                        {!limit ? (
                            <>
                                {slider ? (
                                    <div className={clsx(['articles__list-wrapper', 'g-4'])}>
                                        <Slider settings={settings}>
                                            {articles.map((item, index) => (
                                                <ArticleItem key={index} article={item} />
                                            ))}
                                        </Slider>
                                    </div>
                                ) : (
                                    <Row className={clsx(['articles__list-wrapper', 'g-4'])}>
                                        {articles.map((item, index) => (
                                            <Col xs={12} md={6} lg={4}>
                                                <ArticleItem key={index} article={item} />
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </>
                        ) : (
                            <Row className={clsx(['articles__list-wrapper', 'g-4'])}>
                                {articles.slice(0, limit).map((item, index) => (
                                    <Col xs={12} md={6} lg={4}>
                                        <ArticleItem key={index} article={item} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Container>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default ArticlesList;
