import { ClientApi } from './Request';
import { baseConfig } from '@store/baseConfig';

const { SITE_URL } = baseConfig();

const Menu = {
    get: ({ id }) => ClientApi.get(`${SITE_URL}/wp-json/myplugin/v1/post/${id}/`)
};

export default Menu;
