import React from 'react';

import Modals from '@components/Modals';
import { baseConfig } from '@store/baseConfig';

import './ContactsLine.module.scss';

type ContactsLineProp = {
    phoneText?: string;
    backCall?: boolean;
};

const { SITE_PHONE } = baseConfig();

const ContactsLine = ({ phoneText = SITE_PHONE, backCall = true }: ContactsLineProp) => {
    return (
        <div className='contacts-line'>
            <div className='phone-line'>
                <a href={`tel:${phoneText}`} className='phone-line__link'>
                    <span className='phone-line__icon icon icon-phone'></span>
                    <span className='phone-line__text'>{phoneText}</span>
                </a>
            </div>
            {backCall && (
                <a
                    className='backcall-line'
                    children='Заказать звонок'
                    data-module='BackCall'
                    onClick={(e) =>
                        Modals({
                            item: e.currentTarget
                        })
                    }
                />
            )}
        </div>
    );
};

export default ContactsLine;
